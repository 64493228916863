import { motion } from "framer-motion"
import { Link } from "gatsby"
import React, { useEffect } from "react"
import Div100vh from "react-div-100vh"
import styled from "styled-components"
import { Flex } from "../../layout/reusables"
import { colors } from "../../styles/colors"
import { useRecoilState, useSetRecoilState } from "recoil"
import { menuOpen } from "../../state/atoms"
import { MdClose } from "react-icons/md"
import MenuNavItem from "./menu-nav-item"
import { DigestiveText } from "../../styles/typography"

export default function Menu() {
    const [isMenuOpen, toggleMenu] = useRecoilState(menuOpen)

    return (
        <Wrapper
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <InnerContainer
                as={motion.div}
                initial={{ x: "100%" }}
                exit={{ x: "100%" }}
                transition={{ type: "tween", stiffness: 60 }}
                animate={{ x: 0 }}
                lockBody={isMenuOpen}
            >
                <Header>
                    <div style={{ paddingRight: 15 }}>
                        <Title>
                            Welcome,
                            <br />
                            we hope you PHEAL at home.
                        </Title>
                    </div>
                    <Close
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.2 }}
                        onClick={() => {
                            toggleMenu(false)
                        }}
                    >
                        <MdClose color="black" size={30} />
                    </Close>
                </Header>
                <Nav>
                    {menu.map((item, index) => (
                        <MenuNavItem
                            key={index}
                            item={item}
                            toggleMenu={toggleMenu}
                        />
                    ))}
                </Nav>
            </InnerContainer>
        </Wrapper>
    )
}

const menu = [
    {
        title: "Resources",
        subtitle: "Welcome to the new wave of learning.",
        link: "/resources",
        cta: "Learn More",
    },
    {
        title: "The Pheal Story",
        subtitle: "PEOPLE HERE EVOLVE A LOT.",
        link: "/our-story",
        cta: "Read Now",
    },
    // {
    //     title: "Share Your Story",
    //     subtitle:
    //         "WePHEALTthat holds space for those who are carrying a story inside that’s just waiting to be told.",
    //     link: "/we-phealt-that",
    //     cta: "Share Now",
    // },
    {
        title: "Lookbook",
        subtitle: "Creativity meets a conscious, community-driven enterprise.",
        link: "/shop",
        cta: "Browse Now",
    },
]

const Wrapper = styled(motion.div)`
    position: fixed;
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    /* backdrop-filter: blur(2px); */
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
`
const InnerContainer = styled(Div100vh)`
    background: white;
    /* background: ${props => (props.black ? "#101010" : "white")}; */
    position: absolute;
    height: 100%;
    z-index: 101;
    top: 0;
    right: 0;
    padding: 30px 25px;
    padding-bottom: 160px;
    width: 100%;
    max-width: 450px;
    border-left: 1px solid #ececec;
    border-left: ${props =>
        props.black ? "1px solid #444444" : "1px solid #ECECEC"};
    box-shadow: -4px 0 14px rgba(0, 0, 0, 0.05);
    overflow: ${props => (props.lockBody ? "scroll" : "hidden")};
    color: ${props => (props.black ? "white" : "black")};
    @media (max-width: 550px) {
        padding: 25px 15px;
        /* padding-bottom: 180px; */
        max-width: none;
        width: auto;
        width: -webkit-fill-available;
    }
`
const Header = styled(Flex)`
    justify-content: space-between;
    margin-bottom: 10px;
    background-color: ${colors.yellow};
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    height: 15vh;
    padding: 20px 25px;
`
const Title = styled.h2`
    font-size: 28px;
`
const Nav = styled.nav`
    display: grid;
    grid-template-rows: 1fr;
    gap: 20px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 22vh;
    padding-left: 10px;
    margin-bottom: 160px;
    /* position: sticky; */
    /* top: 0; */
`
const NavItem = styled(Link)`
    font-size: 16px;
    color: ${colors.blue};
    color: white;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 500;
    white-space: nowrap;
    text-align: left;
    margin-right: 15px;
    margin-bottom: 30px;
    :hover {
        text-decoration: underline;
    }
`
const Close = styled(motion.div)`
    cursor: pointer;
    height: 45px;
    width: 45px;
    transition: 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 23px;
    :hover {
        background: ${props => (props.black ? "#999" : "#fafafa")};
    }
`
