/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, PageProps } from "gatsby"

import Header from "./header"
import "./layout.css"
import { GlobalStyles } from "../styles/global-style"
import MailingPopup from "./mailing-popup"
import { useRecoilValue } from "recoil"
import { menuOpen, showingMailingPopup } from "../state/atoms"
import { FirebaseProvider } from "../services/index"
import Footer from "./layout/footer"
import Navbar from "./layout/navbar"
import MobileNavbar from "./layout/mobile-navbar"
import { useMedia } from "react-use"
import { AnimatePresence } from "framer-motion"
import Menu from "./layout/menu"

const Layout = ({ children, dark, animate }) => {
    const showMailingPopup = useRecoilValue(showingMailingPopup)
    const isMenuOpen = useRecoilValue(menuOpen)
    const isWide = useMedia("(min-width: 500px)")

    const html =
        typeof window !== "undefined" ? document.querySelector("html") : {}

    useEffect(() => {
        isMenuOpen
            ? (html.style.overflow = "hidden")
            : (html.style.overflow = "visible")
    }, [isMenuOpen])

    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    return (
        <FirebaseProvider>
            <GlobalStyles />
            {/* <Navbar /> */}
            {showMailingPopup && <MailingPopup />}
            {isWide ? (
                <Navbar dark={dark} animate={animate} />
            ) : (
                <>
                    <MobileNavbar dark={dark} animate={animate} />
                    <AnimatePresence>{isMenuOpen && <Menu />}</AnimatePresence>
                </>
            )}

            <main>{children}</main>
            <Footer />
        </FirebaseProvider>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
