import React, { useEffect } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useSetRecoilState } from "recoil"
import { showingMailingPopup } from "../state/atoms"
import { motion } from "framer-motion"
import Img from "gatsby-image"
import Div100vh from "react-div-100vh"
import { DigestiveText } from "../styles/typography"
import { useLocalStorage } from "react-use"

const IndexPage = ({ data }) => {
    const setMailing = useSetRecoilState(showingMailingPopup)
    const [sendMailingPopup] = useLocalStorage("seenMarketingPopup")

    useEffect(() => {
        if (!sendMailingPopup) {
            //Hasn't seen popup, show after 3 seconds
            setTimeout(() => {
                setMailing(true)
            }, 3000)
        }
    }, [])

    return (
        <Layout dark animate>
            <Container style={{ background: "black" }}>
                <SEO title="PHEAL" />
                <CoverContainer>
                    <Img
                        fluid={data.file.childImageSharp.fluid}
                        style={{ width: "100%", height: "100%" }}
                    />
                </CoverContainer>
                <Title
                    transition={{
                        delay: 0.3,
                        duration: 2,
                        times: [0, 0.2, 0.6, 1],
                    }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: [0, 1, 1, 0] }}
                >
                    PHEAL
                </Title>
            </Container>
        </Layout>
    )
}

export default IndexPage

export const query = graphql`
    query {
        file(relativePath: { eq: "home-cover.png" }) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
    }
`

const CoverContainer = styled(motion.div)`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    margin: 0;
`

const Container = styled(Div100vh)`
    background: black;
    /* height: 100vh; */
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    color: white;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 20px;
`
const Title = styled(DigestiveText)`
    font-size: clamp(120px, 20vw, 300px);
    text-align: center;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
    text-transform: uppercase;
    z-index: 2;
    margin-bottom: 40px;
    opacity: 0;
`
