import React from "react"
import styled from "styled-components"
import Layout from "../../components/layout"
import CreateStory from "../../components/we-phealt-that/create-story"
import {
    ContentWrapper,
    Flex,
    TextWrapper,
    Wrapper,
} from "../../layout/reusables"
import { colors } from "../../styles/colors"
import { DigestiveText } from "../../styles/typography"
import { BiChevronDown } from "react-icons/bi"
import scrollTo from "gatsby-plugin-smoothscroll"
import Fade from "react-reveal/Fade"
export default function Create({}) {
    return (
        <Layout dark>
            <Wrapper>
                <ContentWrapper
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "20vh 15px 10vh",
                    }}
                >
                    <Title noUppercase mBottom={5}>
                        Share your story
                    </Title>
                    <p style={{ color: colors.pink, marginBottom: 45 }}>
                        (Anonymously)
                    </p>
                    <TextWrapper>
                        <Intro>
                            <Fade>
                                We live in a world that is constantly changing,
                                shifting and evolving. Yet, one constant remains
                                the same no matter the millennia or the society
                                – people love to tell stories. And we love to
                                listen to them. No matter how short or long, a
                                genuine story can teach us lessons, awaken us to
                                new perspectives and help us evolve to be more
                                connected to our shared human experience.
                            </Fade>
                            <br />
                            <br />
                            <Fade>
                                WePHEALTthat holds space for those who are
                                carrying a story inside that’s just waiting to
                                be told. By allowing ourselves to be vulnerable
                                about our journeys, passions, struggles and
                                triumphs, we’re helping to create a radical
                                shift towards a more open and honest world.
                            </Fade>
                        </Intro>
                    </TextWrapper>
                    <Flex
                        whileHover={{ y: 4 }}
                        direction="column"
                        align="center"
                        justify="center"
                        style={{ cursor: "pointer", marginBottom: 30 }}
                        onClick={() => scrollTo("#createStory")}
                    >
                        <BiChevronDown size={30} />
                        <p
                            style={{
                                fontSize: 14,
                                fontWeight: 500,
                                letterSpacing: 1.6,
                                fontFamily: "Haas",
                            }}
                        >
                            START WRITING
                        </p>
                    </Flex>
                    <CreateStory />
                </ContentWrapper>
            </Wrapper>
        </Layout>
    )
}

const Title = styled(DigestiveText)`
    font-size: clamp(75px, 9vw, 150px);
    text-align: center;
`
const Intro = styled.p`
    font-size: 18px;
    line-height: 1.5;
    color: #1d1d1d;
    margin-bottom: 30px;
`
