import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { OutlineButton } from "../../styles/buttons"
import { DigestiveText } from "../../styles/typography"

export default function MenuNavItem({ item, toggleMenu }) {
    const { title, subtitle, link, cta } = item ?? null
    return (
        <Container to={link} onClick={() => toggleMenu(false)}>
            <Title noSubtitle={!subtitle}>{title}</Title>
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
            <Link to={link} onClick={() => toggleMenu(false)}>
                <OutlineButton dark>{cta}</OutlineButton>
            </Link>
            <Break />
        </Container>
    )
}

const Container = styled(Link)`
    display: grid;
    grid-template-rows: 1fr;
    gap: 10px;
    text-decoration: none;
    color: black;
`
const Title = styled(DigestiveText)`
    font-size: ${props => (props.noSubtitle ? "70px" : "45px")};
`
const Subtitle = styled.p`
    color: #2f2f2f;
    line-height: 1.3;
`
const Break = styled.hr`
    width: 100%;
    opacity: 0.3;
`
