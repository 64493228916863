import React from "react"
import styled from "styled-components"
import Socials from "./socials"

const Footer = () => {
    return (
        <Container>
            <Socials />
            <Copyright>© PHEAL, 2020</Copyright>
        </Container>
    )
}

export default Footer

const Container = styled.footer`
    background: black;
    padding: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const Copyright = styled.p`
    color: white;
    font-size: 14px;
`
