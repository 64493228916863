import React, { useState } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import CollaboratorItem from "../components/resources/collaborator-item"
import ResourcesHeader from "../components/resources/resources-header"
import SEO from "../components/seo"
import { ContentWrapper, Wrapper } from "../layout/reusables"
import { DigestiveText } from "../styles/typography"
import * as _ from "lodash"

export default function ResourcesPage({ data }) {
    const [collaboratorChunks, setCollaboratorChunks] = useState([])
    const { pageData } = data
    const { collaborators_page } = pageData
    const {
        collaboratorsTitle,
        upcomingCollaborators,
        eyebrow,
        title,
        cover,
    } = collaborators_page

    const shuffleArray = array => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1))
            ;[array[i], array[j]] = [array[j], array[i]]
        }
        return array
    }
    const shuffledCollaboratorChunks = _.chunk(
        shuffleArray(upcomingCollaborators),
        5
    )

    return (
        <Layout>
            <SEO title="Resources" />
            <Wrapper>
                <ResourcesHeader
                    eyebrow={eyebrow}
                    title={title}
                    image={cover.localFile.childImageSharp.fluid}
                />
                <ContentWrapper style={{ maxWidth: 1250 }}>
                    <Title noUppercase>{collaboratorsTitle}</Title>
                    {shuffledCollaboratorChunks.map(
                        (collaborators, mainIndex) => (
                            <CollaboratorGrid key={mainIndex}>
                                {collaborators.map((item, index) => (
                                    <CollaboratorItem
                                        key={index}
                                        index={index}
                                        item={item}
                                        inverse={mainIndex % 2 === 1}
                                    />
                                ))}
                            </CollaboratorGrid>
                        )
                    )}
                </ContentWrapper>
            </Wrapper>
        </Layout>
    )
}

export const ResourcesQuery = graphql`
    query resourcesQuery {
        pageData: wpPage(title: { eq: "Resources" }) {
            collaborators_page {
                collaboratorsTitle
                eyebrow
                cover {
                    sourceUrl
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 1400, quality: 100) {
                                ...GatsbyImageSharpFluid_noBase64
                            }
                        }
                    }
                }
                title
                upcomingCollaborators {
                    description
                    image {
                        sourceUrl
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 800, quality: 100) {
                                    ...GatsbyImageSharpFluid_noBase64
                                }
                            }
                        }
                    }
                    name
                }
                videoUrl
            }
        }
    }
`

const Title = styled(DigestiveText)`
    font-size: clamp(60px, 8vw, 110px);
    text-align: center;
    color: black;
    padding: 7vh 20px;
`
const CollaboratorGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 60px 30px;
    padding: 20px;

    @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 550px) {
        grid-template-columns: repeat(1, 1fr);
        padding: 20px;
        gap: 40px 15px;
    }
`
