import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { MdClose } from "react-icons/md"
import { motion } from "framer-motion"
import Loader from "react-loader-spinner"
import axios from "axios"
import { useLocalStorage } from "react-use"
import { useSetRecoilState } from "recoil"
import { showingMailingPopup } from "../state/atoms"
import { MainButton } from "../styles/buttons"
import { TextWrapper } from "../layout/reusables"

const Container = styled(motion.div)`
    position: fixed;
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
    z-index: 6;
    display: flex;
    align-items: center;
    justify-content: center;
`
const InnerContainer = styled.div`
    background: black;
    padding: 60px;
    max-width: 500px;
    width: 100%;
    /* border: 5px solid #9DCFCA; */
    position: relative;
    text-align: center;

    @media (max-width: 550px) {
        padding: 60px 25px;
        margin: 20px;
    }
`
const Title = styled.h2`
    color: white;
    font-family: "Haas";
    margin-bottom: 8px;

    @media (max-width: 550px) {
        font-size: 22px;
        line-height: 1.4;
    }
`
const Subtitle = styled.p`
    color: white;
    opacity: 0.7;
    margin-bottom: 30px;
    line-height: 1.4;
    @media (max-width: 550px) {
        font-size: 16px;
    }
`
const Close = styled.div`
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border-radius: 20px;
`
const Form = styled.form`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-bottom: 20px;
`
const Input = styled.input`
    background: #ffffff10;
    border: none;
    outline: none;
    padding: 15px 20px;
    margin-bottom: 10px;
    font-weight: 400;
    text-align: center;
    font-size: 16px;
    color: white;
    font-family: "Haas";
`
const Row = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
const SubmitBtn = styled.button`
    width: 100%;
    color: white;
    cursor: pointer;
    color: black;
    background: white;
    outline: none;
    border: none;
    font-family: "Haas";
    padding: 10px 20px;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 10px;
    :disabled {
        background: #aaa;
        cursor: not-allowed;
    }
`

const Logo = styled.img`
    margin: 0;
    max-width: 100px;
`

const MailingPopup = () => {
    const [seenMarketingPopup, setValue] = useLocalStorage("seenMarketingPopup")
    const setShowing = useSetRecoilState(showingMailingPopup)

    const [state, setState] = useState({
        firstName: "",
        lastName: "",
        email: "",
    })
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState("")
    const [subscribed, setSubscribed] = useState(false)

    //Update local storage value
    useEffect(() => {
        setValue(true)
    }, [])

    //Set a timer to dismiss the modal when subscribed
    useEffect(() => {
        if (subscribed) {
            setTimeout(() => {
                setShowingPopup()
            }, 2500)
        }
    }, [subscribed])

    const setShowingPopup = () => {
        // dispatch({ type: "TOGGLE_POPUP" })
        //Mark as seen popup?
    }

    const updateInput = e => {
        const name = e.target.name
        const value = e.target.value

        setState({ ...state, [name]: value })
    }

    const disabled = () => {
        return state.firstName === "" || state.email === ""
    }

    const handleSubmit = async e => {
        e.preventDefault()
        setLoading(true)

        const { firstName, lastName, email } = state

        //What can go wrong?
        //1. Netlify server can give bad response
        //2. Email can be wrong

        try {
            await axios.post(
                "/.netlify/functions/mailchimp-subscribe",
                JSON.stringify({
                    firstName,
                    lastName,
                    email,
                })
            )
            setLoading(false)
            setSubscribed(true)
        } catch (err) {
            setSubscribed(true)
            console.log(err)
        }
    }

    if (subscribed) {
        return (
            <Container
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <InnerContainer
                    style={{
                        minHeight: 200,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Close onClick={() => setShowing(false)}>
                        <MdClose
                            size={30}
                            color="white"
                            style={{ margin: 0 }}
                        />
                    </Close>
                    <Title style={{ marginBottom: 30 }}>
                        Thanks for being a part of the evolution.
                    </Title>
                    <Logo src={require("../images/Pheal-Logo-White.png")} />
                </InnerContainer>
            </Container>
        )
    }

    return (
        <Container
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <InnerContainer>
                <Close onClick={() => setShowing(false)}>
                    <MdClose size={30} color="white" style={{ margin: 0 }} />
                </Close>
                <TextWrapper>
                    <Title>People Here Evolve A Lot.</Title>
                    <Subtitle>
                        Join a bold community of learners, thinkers and change
                        makers.
                    </Subtitle>
                </TextWrapper>
                <Form onSubmit={handleSubmit}>
                    <Input
                        name="email"
                        value={state.email}
                        onChange={updateInput}
                        placeholder="Email Address*"
                        required
                        type="email"
                    />
                    <Input
                        name="firstName"
                        value={state.firstName}
                        onChange={updateInput}
                        placeholder="First Name*"
                        required
                    />
                    <Input
                        type="text"
                        name="lastName"
                        value={state.lastName}
                        onChange={updateInput}
                        placeholder="Last Name"
                        style={{ marginBottom: 30 }}
                    />

                    <Row>
                        <MainButton
                            style={{ width: "100%" }}
                            type="submit"
                            disabled={disabled()}
                        >
                            Submit
                        </MainButton>
                        {loading ? (
                            <Loader
                                type="Circles"
                                color="white"
                                height={30}
                                width={30}
                            />
                        ) : (
                            <p style={{ margin: 0 }}>{message}</p>
                        )}
                    </Row>
                </Form>
                <Logo src={require("../images/Pheal-Logo-White.png")} />
            </InnerContainer>
        </Container>
    )
}

export default MailingPopup
