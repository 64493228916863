import { navigate } from "gatsby"
import React from "react"
import styled from "styled-components"
import { Flex, TextWrapper } from "../../layout/reusables"
import { OutlineButton } from "../../styles/buttons"
import { colors } from "../../styles/colors"
import { DigestiveText } from "../../styles/typography"

export default function Header({ intro, heading, subtitle }) {
    return (
        <Container>
            <Flex
                style={{ height: "100%" }}
                direction="column"
                align="center"
                justify="center"
            >
                <TextWrapper style={{ maxWidth: 900 }}>
                    <Intro>We Phealt That</Intro>
                    <Heading noUppercase>
                        We all have a story,
                        <br />
                        but we all tell it differently.
                    </Heading>
                    <Subtitle>PEOPLE HERE EVOLVE A LOT</Subtitle>
                </TextWrapper>
                <OutlineButton
                    onClick={() => navigate("/we-phealt-that/create")}
                    dark
                >
                    SHARE YOUR STORY
                </OutlineButton>
            </Flex>
        </Container>
    )
}

const Container = styled.div`
    text-align: center;
    background: ${colors.yellow};
    min-height: 70vh;
    max-height: 100vh;
    padding: 20vh 5vh;

    @media (max-width: 550px) {
        padding: 15vh 3vh;
    }
`
const Intro = styled.p`
    text-transform: uppercase;
    margin-bottom: 30px;
`
const Heading = styled(DigestiveText)`
    font-size: clamp(75px, 9vw, 200px);
    margin-bottom: 30px;
`
const Subtitle = styled.p`
    text-transform: uppercase;
    margin-bottom: 30px;
`
