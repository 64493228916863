import React, { useState } from "react"
import styled from "styled-components"
import { Flex } from "../../layout/reusables"
import Img from "gatsby-image"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/opacity.css"
import { motion } from "framer-motion"
export default function LookBookItem({ item, index }) {
    const [hovered, setHovered] = useState(false)

    const {
        concept,
        creativeDirection,
        featuredItems,
        styling,
        talent,
        photography,
    } = item ?? ""

    return (
        <Container
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            {/* <Image src={item.image.sourceUrl} /> */}
            <motion.div
                whileHover={{
                    rotateZ: `${index % 2 === 1 ? "-" : ""}7deg`,
                    scale: 1.2,
                }}
                transition={{ duration: 0.3 }}
            >
                <LazyLoadImage
                    height={"100%"}
                    src={item.image.sourceUrl} // use normal <img> attributes as props
                    width={"100%"}
                    effect="opacity"
                    style={{
                        height: "100%",
                        backgroundColor: "#eee",
                    }}
                />
            </motion.div>
            {/* <div>
                <Img
                    fluid={item.image.localFile.childImageSharp.fluid}
                    style={{ width: "100%", height: "100%" }}
                />
            </div> */}
            <Meta
                initial={{ opacity: 0, y: "15%" }}
                animate={{ opacity: hovered ? 1 : 0, y: hovered ? 0 : "15%" }}
                transition={{ duration: 0.3, easing: "ease-in-out" }}
            >
                {featuredItems && (
                    <Label style={{ marginBottom: 20 }}>
                        Featured: <span>{featuredItems}</span>
                    </Label>
                )}
                {concept && (
                    <Label>
                        Concept: <span>{concept}</span>
                    </Label>
                )}
                {creativeDirection && (
                    <Label>
                        Creative Direction: <span>{creativeDirection}</span>
                    </Label>
                )}
                {styling && (
                    <Label>
                        Styling: <span>{styling}</span>
                    </Label>
                )}
                {talent && (
                    <Label>
                        Talent: <span>{talent}</span>
                    </Label>
                )}
            </Meta>
        </Container>
    )
}

const Container = styled.div`
    position: relative;
    overflow: hidden;
    transition: 0.2s ease;
`
const Image = styled.img`
    width: 100%;
    height: 100%;
`
const Meta = styled(Flex)`
    pointer-events: none;
    background: rgb(0, 0, 0);
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 80%
    );
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60%;
    padding: 35px 30px;
    flex-direction: column;
    justify-content: flex-end;

    @media (max-width: 768px) {
        padding: 30px 20px 15px;
    }
`
const Label = styled.p`
    color: white;
    font-size: 14px;
    line-height: 1.4;
    span {
        font-weight: 500;
    }

    @media (max-width: 550px) {
        font-size: 12px;
    }
`
