import React from "react"
import styled from "styled-components"
import { OutlineButton } from "../../styles/buttons"
import { CgArrowLongLeft, CgArrowLongRight } from "react-icons/cg"

const ProductCarousel = () => {
    return (
        <Container>
            <Gallery>
                <ArrowButton>
                    <CgArrowLongLeft size={50} color="black" />
                </ArrowButton>
                <ImageGrid>
                    <ProductImage src={require("../../images/favicon.png")} />
                    <ProductImage src={require("../../images/favicon.png")} />
                    <ProductImage src={require("../../images/favicon.png")} />
                </ImageGrid>
                <ArrowButton>
                    <CgArrowLongRight size={50} color="black" />
                </ArrowButton>
            </Gallery>
            <OutlineButton dark>Shop Now</OutlineButton>
        </Container>
    )
}

export default ProductCarousel

const Container = styled.section`
    padding: 5vh 0;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const Gallery = styled.div`
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 25px;
    width: 100%;
    align-items: center;
    margin-bottom: 35px;
`
const ImageGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
`
const ProductImage = styled.img`
    width: 100%;
    height: auto;
`
const ArrowButton = styled.button`
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
`
