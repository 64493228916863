import React from "react"
import styled from "styled-components"
import Channels from "../components/how-it-works/channels"
import Collaborators from "../components/how-it-works/collaborators"
import WePhealtThat from "../components/how-it-works/we-phealt-that"
import { SectionContainer } from "../components/how-it-works/reusables"
import Welcome from "../components/how-it-works/welcome"
import Layout from "../components/layout"
import { ContentWrapper, TextWrapper, Wrapper } from "../layout/reusables"
import { colors } from "../styles/colors"
import PhealSeasons from "../components/how-it-works/pheal-seasons"
import MembershipAndPricing from "../components/how-it-works/membership-and-pricing"
import { useTransform, useViewportScroll } from "framer-motion"
import WaveBlock from "../components/how-it-works/wave-block"
import FeatureSection from "../components/how-it-works/section"
import SEO from "../components/seo"

export const SIGN_UP_FORM_URL = "https://forms.gle/vzPVYB2oEpkxPhzG8"

const HowItWorksPage = () => {
    const { scrollYProgress } = useViewportScroll()
    const xRange = [0, 0.03, 0.07, 0.11]
    const colorRange = [colors.yellow, colors.pink, colors.orange, colors.blue]
    const background = useTransform(scrollYProgress, xRange, colorRange)

    return (
        <Layout>
            <SEO title="How it works." />
            <Wrapper style={{ background: colors.yellow }}>
                {/* <Snap> */}
                <SectionContainer
                    background={background}
                    style={{ background: colors.yellow }}
                >
                    <Welcome />
                </SectionContainer>
                <SectionContainer background={colors.blue}>
                    <WaveBlock fill="#00039E" />
                    <FeatureSection
                        title="The Collaborators"
                        copy="PHEAL collaborators all have their own specific niches.
                        Some may relate to social issues such as racism, gender
                        and sexuality and climate change; some may fall under
                        the broad spectrum of the sciences, technology, the arts
                        or business and entrepreneurship. Whatever your niche,
                        we want you to explore and dive as deeply as you desire
                        into the topics that matter to you."
                    />
                </SectionContainer>
                <SectionContainer background={colors.orange}>
                    <WaveBlock fill="#BF3D00" />
                    <FeatureSection
                        title="Channels"
                        copy="Once you sign up as a collaborator, you’re given access
                        to your personal PHEAL channel which acts as your main
                        profile. Your channel comes with basic access to an
                        individual resource archive that you can categorize and
                        search by topic or subject. Collaborators can also
                        upload original media content to their channel whether
                        it be text, image, audio, video or a combination.
                        Depending on your subscription package, you can access
                        additional features to customize your channel."
                        textColor={colors.yellow}
                        ctaCopy="See a sneak peak"
                        newTab
                        to="/channels"
                        align="right"
                    />
                </SectionContainer>
                <SectionContainer background={colors.pink}>
                    <WaveBlock fill="#B000B0" />
                    <FeatureSection
                        title="We Phealt That"
                        copy="Our featured collaborators section, known here as WePhealtThat, is dedicated to collaborators who we want to acknowledge for their amazing contributions to the PHEAL community. With each new season, new collaborators will be selected and featured to help increase their visibility and promote the work that they’ve been doing."
                        textColor={colors.blue}
                        // ctaCopy="Share your story"
                        // align="right"
                    />
                </SectionContainer>
                <SectionContainer background={colors.green}>
                    <WaveBlock fill="#1C8F2A" />
                    <FeatureSection
                        title="Pheal Seasons"
                        copy="Like the real world, PHEAL works in seasons. Our seasons
                        are three-month cycles that usher in new updates and
                        additions to the site. We work this way because it gives
                        us the time to fully reflect on feedback, develop new
                        ideas and introduce changes that genuinely improve the
                        PHEAL experience."
                        textColor={colors.blue}
                        align="right"
                        // ctaCopy="Get in on this season"
                    />
                </SectionContainer>
                <SectionContainer
                    style={{ display: "block", height: "auto" }}
                    background={colors.blue}
                >
                    <WaveBlock fill="#00039E" />
                    <MembershipAndPricing />
                </SectionContainer>
                {/* </Snap> */}
            </Wrapper>
        </Layout>
    )
}

export default HowItWorksPage

const Title = styled.h1`
    margin-bottom: 80px;
`
const Subtitle = styled.h2`
    margin-bottom: 20px;
`
const Copy = styled.p`
    line-height: 1.6;
    font-size: 20px;
    margin-bottom: 20px;
`

const Snap = styled.div`
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100vh;
    z-index: 0;
    scroll-snap-type: y mandatory;
    -webkit-scroll-snap-type: y mandatory;
    -moz-scroll-snap-type: y mandatory;
`

const Section = styled.section`
    background-color: ${colors.orange};
    background-color: ${props => props.alt && "white"};
    position: relative;
    scroll-snap-align: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 3%;
    align-items: flex-end;
    justify-content: center;
    /* padding: 50px; */
    @media (max-width: 600px) {
        align-items: center;
        padding: 10px 40px;
    }
`
