import React from "react"
import styled from "styled-components"
import Masonry from "react-masonry-css"
import "./masonry-style.css"
import { OutlineButton } from "../../styles/buttons"
import LookBookItem from "./lookbook-item"
import { useMedia } from "react-use"
import { DigestiveText } from "../../styles/typography"

const ProductMasonryGrid = ({ gallery }) => {
    const isWide = useMedia("(min-width: 768px)")
    return (
        <Container>
            <Title>Lookbook</Title>

            <Masonry
                breakpointCols={isWide ? 3 : 2}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
            >
                {gallery.map((item, index) => (
                    <LookBookItem key={index} index={index} item={item} />
                ))}
            </Masonry>
            {/* <OutlineButton dark>Shop Now</OutlineButton> */}
        </Container>
    )
}

export default ProductMasonryGrid

const Container = styled.section`
    padding: 5vh 0;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const Title = styled(DigestiveText)`
    font-size: clamp(60px, 8vw, 110px);
    text-align: center;
    color: black;
    padding: 7vh 20px;
`
const Gallery = styled.div`
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 25px;
    width: 100%;
    align-items: center;
    margin-bottom: 35px;
`
const ImageGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
`
const ProductImage = styled.img`
    width: 100%;
    height: 100%;
`
const ArrowButton = styled.button`
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
`
