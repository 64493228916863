import React, { useState } from "react"
import styled from "styled-components"
import { Flex } from "../../layout/reusables"
import Img from "gatsby-image"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/opacity.css"
import Fade from "react-reveal/Fade"

export default function CollaboratorItem({ item, index, inverse }) {
    const [hovered, setHovered] = useState(false)

    const { image, name, description } = item ?? ""

    const big = inverse ? index === 0 : index === 2

    return (
        <Container inverse={inverse} big={big}>
            {/* <Image src={item.image.sourceUrl} /> */}
            {/* <Image
                height={"100%"}
                src={item.image.sourceUrl} // use normal <img> attributes as props
                width={"100%"}
                effect="opacity"
                style={{ objectFit: "cover", maxHeight: "80vh" }}
            /> */}
            <div>
                <Img
                    fluid={item.image.localFile.childImageSharp.fluid}
                    objectFit="cover"
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        maxHeight: "80vh",
                        minHeight: "45vh",
                    }}
                />
            </div>
            <Meta direction="column">
                <Fade>
                    <Label big={big}>
                        <span>{name}</span>
                    </Label>
                    <Label>{description}</Label>
                </Fade>
            </Meta>
        </Container>
    )
}

const Container = styled.div`
    position: relative;
    overflow: hidden;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-row: ${props => props.big && "1 / span 2"};
    grid-column: ${props =>
        props.big && (props.inverse ? "1 / span 2" : "3 / span 2")};

    @media (max-width: 768px) {
        grid-column: auto;
        grid-row: auto;
    }

    @media (max-width: 550px) {
        grid-column: auto;
        grid-row: auto;
    }
`
const Image = styled(LazyLoadImage)`
    width: 100%;
    /* height: 100%; */
    min-height: 50vh;

    @media (max-width: 1050px) {
        min-height: 40vh;
    }
`
const Meta = styled(Flex)`
    margin-top: 15px;
`
const Label = styled.p`
    color: black;
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 5px;

    span {
        font-size: ${props => (props.big ? "32px" : "24px")};
        font-weight: 500;
        line-height: 1.3;

        @media (max-width: 550px) {
            font-size: 24px;
        }
    }

    @media (max-width: 550px) {
        font-size: 14px;
    }
`
