import { graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CreateStory from "../../components/we-phealt-that/create-story"
import Header from "../../components/we-phealt-that/header"
import StoryItem from "../../components/we-phealt-that/story-item"
import { ContentWrapper, Wrapper } from "../../layout/reusables"

const WePhealtThat = ({ data }) => {
    const { story } = data
    const phealStory = {
        title: story.title,
        description:
            "PHEAL is an educational platform designed to rethink how we approach learning in the modern world. A genuine educational experience takes our world and breaks it open, giving us the space for an honest exploration of our society and culture where no topic is too taboo and every voice has a chance to be heard.",
        image: story.featuredImage.node.localFile.childImageSharp.fluid,
    }
    return (
        <Layout dark>
            <SEO title="We Phealt That" />
            <Wrapper>
                <Header />
                <ContentWrapper style={{ padding: "10vh 20px" }}>
                    <StoryItem story={phealStory} />
                </ContentWrapper>
            </Wrapper>
        </Layout>
    )
}

export default WePhealtThat

export const query = graphql`
    query MyQuery {
        wpPage(title: { eq: "We Phealt That" }) {
            title
            we_phealt_that_page {
                subtitle
                intro
                headline
                description
                cta
            }
        }
        story: wpPage(id: { eq: "cG9zdDo0Mjk=" }) {
            title
            content
            featuredImage {
                node {
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 1200, quality: 100) {
                                ...GatsbyImageSharpFluid_noBase64
                            }
                        }
                    }
                }
            }
        }
    }
`
