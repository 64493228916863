import React from "react"
import styled from "styled-components"
import {
    FaFacebookF,
    FaInstagram,
    FaTwitter,
    FaPinterest,
} from "react-icons/fa"
import { motion } from "framer-motion"
import { colors } from "../../styles/colors"

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 120px;
    margin: 0 auto;
    margin-bottom: 15px;
`
const SocialLink = styled(motion.a)`
    color: white;
    transition: 0.2s ease;
    :hover {
        color: ${colors.pink};
    }
`

const Socials = () => (
    <Container>
        <SocialLink
            whileHover={{ scale: 1.1 }}
            href="https://www.facebook.com/pheal.co"
            target="_blank"
        >
            <FaFacebookF size={25} />
        </SocialLink>

        <SocialLink
            whileHover={{ scale: 1.1 }}
            href="https://www.instagram.com/pheal.co/"
            target="_blank"
        >
            <FaInstagram size={25} />
        </SocialLink>
        <SocialLink
            whileHover={{ scale: 1.1 }}
            href="https://www.pinterest.com/PeopleHereEvolveALot/"
            target="_blank"
        >
            <FaPinterest size={25} />
        </SocialLink>
    </Container>
)

export default Socials
