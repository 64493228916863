import { graphql, navigate } from "gatsby"
import React, { useRef, useState } from "react"
import styled, { keyframes } from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { ContentWrapper, Flex, TextWrapper, Wrapper } from "../layout/reusables"
import { OutlineButton } from "../styles/buttons"
import Fade from "react-reveal/Fade"
import { TiArrowUp } from "react-icons/ti"
import { colors } from "../styles/colors"
import { useWindowScroll } from "react-use"

export default function OurStoryPage({ data }) {
    const { wpPage } = data

    const titleRef = useRef()

    const { x, y } = useWindowScroll()
    const scrollTopVisible = y > 200

    const handleScrollToTop = () => {
        titleRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
        })
    }

    return (
        <Layout dark>
            <SEO title="The Story of PHEAL" />
            <Wrapper>
                <ContentWrapper style={{ padding: "20vh 20px" }}>
                    <Flex direction="column" align="center">
                        <HeaderContainer>
                            <Img
                                fluid={
                                    wpPage.featuredImage.node.localFile
                                        .childImageSharp.fluid
                                }
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                }}
                            />
                            <TitleBlock>
                                <TextWrapper ref={titleRef}>
                                    <Title>{wpPage.title}</Title>
                                </TextWrapper>
                            </TitleBlock>
                        </HeaderContainer>
                        <TextWrapper>
                            <Fade>
                                <ArticleContainer>
                                    <Article
                                        dangerouslySetInnerHTML={{
                                            __html: wpPage.content,
                                        }}
                                    />
                                </ArticleContainer>
                                <Flex justify="center">
                                    <OutlineButton
                                        dark
                                        disabled
                                        style={{ border: "none" }}
                                        // onClick={() =>
                                        //     navigate("/we-phealt-that/create")
                                        // }
                                    >
                                        We Phealt That, Coming Soon
                                    </OutlineButton>
                                </Flex>
                            </Fade>
                        </TextWrapper>
                    </Flex>
                </ContentWrapper>
                {scrollTopVisible && (
                    <Container onClick={() => handleScrollToTop()}>
                        <TiArrowUp
                            style={{ marginBottom: 0 }}
                            color={colors.yellow}
                            size={34}
                        />
                    </Container>
                )}
            </Wrapper>
        </Layout>
    )
}

export const ourStoryQuery = graphql`
    query OurStoryQuery {
        wpPage(id: { eq: "cG9zdDo0Mjk=" }) {
            title
            content
            featuredImage {
                node {
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 1200, quality: 100) {
                                ...GatsbyImageSharpFluid_noBase64
                            }
                        }
                    }
                }
            }
        }
    }
`

const enter = keyframes`
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`
const Container = styled.div`
    width: 40px;
    height: 40px;
    position: fixed;
    z-index: 98;
    bottom: 30px;
    right: 30px;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    padding: 10px;
    cursor: pointer;
    opacity: 0;
    transform: translateY(10px);

    animation: ${enter} 0.4s ease-out forwards;
    @media (max-width: 500px) {
        bottom: 20px;
        right: 10px;
    }
`

const Text = styled.p`
    margin: 0;
    text-transform: uppercase;
    color: ${colors.yellow};
`

const HeaderContainer = styled.div`
    width: 100%;
    height: 60vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15vh;
`
const Image = styled.img``
const TitleBlock = styled.div`
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -10%;
    padding: 25px 35px;
    z-index: 1;
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.08);
`
const Title = styled.h1`
    font-size: clamp(24px, 5vw, 32px);
    line-height: 1.2;
    font-weight: 500;
`
const ArticleContainer = styled.div`
    width: 100%;
    margin-bottom: 50px;
`
const Article = styled.div`
    div {
        margin-bottom: 50px;
        width: 100%;
        /* display: none; */
        img {
            width: 100%;
        }
    }
    .wp-block-image {
        figure {
            span {
                width: 100% !important;
            }
        }
    }
    p {
        line-height: 1.5;
        font-size: 18px;
        margin-bottom: 20px;
    }
    blockquote {
        p {
            font-size: 28px !important;
            font-style: italic;
            text-align: center;
        }
    }
`
