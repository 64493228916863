import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { TextWrapper } from "../../layout/reusables"
import { BsArrowRight } from "react-icons/bs"
import { Link } from "gatsby"

export default function StoryItem({ story }) {
    return (
        <Link style={{ textDecoration: "none" }} to="/our-story">
            <Container>
                <div>
                    <Img
                        fluid={story.image}
                        style={{
                            width: "100%",
                            height: "100%",
                        }}
                    />
                </div>
                <Content>
                    <TextWrapper>
                        <Title>{story.title}</Title>
                        <Description>{story.description}</Description>
                        <BsArrowRight size={50} />
                    </TextWrapper>
                </Content>
            </Container>
        </Link>
    )
}

const Container = styled.div`
    display: grid;
    grid-template-columns: 40% 1fr;
    align-items: center;
    gap: 4%;
    color: black;

    @media (max-width: 750px) {
        grid-template-columns: 1fr;
    }
`
const Image = styled.img``
const Content = styled.div``
const Title = styled.h2`
    font-size: 35px;
    margin-bottom: 30px;
`
const Description = styled.p`
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 1.4;
`
