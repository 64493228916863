import { navigate } from "gatsby"
import React, { useCallback, useContext, useState } from "react"
import Loader from "react-loader-spinner"
import styled from "styled-components"
import Layout from "../components/layout"
import { Flex } from "../layout/reusables"
import { FirebaseContext } from "../services"
import { RegisterUser } from "../services/auth"
import {
    collaboratorAccessRef,
    collaboratorsRef,
} from "../state/FirebaseProvider"
import { MainButton } from "../styles/buttons"
import { DigestiveText } from "../styles/typography"

const AuthPage = () => {
    const { firebase, authToken, setAuthToken } = useContext(FirebaseContext)

    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const handleSignUp = () => {
        RegisterUser(email, password, onAuthenticated)
    }

    // Method for signing up and logging in.
    const handleSignupAndLogin = useCallback(async () => {
        setLoading(true)
        let authError
        try {
            //First, we need to make sure this user has been given access to
            const accessSnapshot = await collaboratorAccessRef
                .where("email", "==", email)
                .get()
            if (accessSnapshot.empty) {
                alert(
                    "Sorry, sign up is available to pre-approved collaborators only!"
                )
                setLoading(false)
                return
            }
            const {
                user,
            } = await firebase
                .auth()
                .createUserWithEmailAndPassword(email, password)

            // If successful and we have a user the set the authToken.
            if (user) {
                const { refreshToken, uid } = user
                setAuthToken(refreshToken)
                onAuthenticated(uid)
            }

            //Create a new collaborator
            await collaboratorsRef.doc(user.uid).set({
                email,
                id: user.uid,
            })
            // If there is an error set the authError to the new error
        } catch (error) {
            authError = error
        } finally {
            // If there is an authError and the code is that the email is already in user try to sign the user
            //  in with the email and password instead.
            if (authError?.code === "auth/email-already-in-use") {
                const {
                    user,
                } = await firebase
                    .auth()
                    .signInWithEmailAndPassword(email, password)
                // We’ve been here before… set the authToken if there is a user.
                if (user) {
                    console.log(user)
                    const { refreshToken } = user
                    setAuthToken(refreshToken)
                    onAuthenticated(user.uid)
                    setLoading(false)
                }
            }
        }
    }, [email, password, firebase, setAuthToken])

    const onAuthenticated = async userId => {
        //Get the user and see what they have set up so far
        const collaborator = await collaboratorsRef.doc(userId).get()
        // console.log(collaborator.data())
        // return
        navigate("/profile")
    }

    return (
        <Layout>
            <Flex
                style={{ height: "100vh" }}
                direction="column"
                align="center"
                justify="center"
            >
                <DigestiveText mBottom={25}>Create your account</DigestiveText>
                <Input
                    type="email"
                    value={email}
                    name="email"
                    placeholder="you@email.com"
                    onChange={e => setEmail(e.target.value)}
                />
                <Input
                    type="password"
                    value={password}
                    name="password"
                    placeholder="Choose a password"
                    onChange={e => setPassword(e.target.value)}
                />
                <Input
                    type="password"
                    value={confirmPassword}
                    name="confirmPassword"
                    placeholder="Re-enter your password"
                    onChange={e => setConfirmPassword(e.target.value)}
                />
                <MainButton
                    style={{ marginBottom: 15 }}
                    dark
                    onClick={() => handleSignupAndLogin()}
                >
                    Sign up
                </MainButton>
                {loading && (
                    <Loader type="Oval" height={30} width={30} color="black" />
                )}
            </Flex>
        </Layout>
    )
}

export default AuthPage

const Input = styled.input`
    background-color: #f3f3f3;
    border-radius: 3px;
    font-size: 18px;
    padding: 15px 20px;
    width: 100%;
    max-width: 400px;
    margin-bottom: 15px;
    font-family: "Haas";
    border: none;
    outline: none;
`
